/* @import url('https://fonts.googleapis.com/css?family=Outfit'); */
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Outfit;
  src: url('../fonts/Outfit-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

html,
body,
#root {
  height: 100%;
  font-size: 1rem;
  letter-spacing: -0.1px;
}

body {
  overflow-y: scroll;
}

p {
  letter-spacing: 0.01em;
  font-feature-settings: 'pnum' on, 'lnum' on;
}

a {
  text-decoration: none;
  color: #5b3de3;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}

img {
  color: #5b3de3;
  font-size: 14px;
}
input {
  font-family: Outfit,Tangerine;
}

.img-blend-color {
  background: #694ce6cc;
  background-blend-mode: multiply;
}

.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.imgObjectFit {
  object-fit: cover;
}

*:focus {
  outline: 0.0625rem dashed rgba(0, 0, 0, 0.1);
}

.shadow {
  box-shadow: rgb(255, 255, 255) 0rem 0rem 0rem 0rem,
    rgba(0, 0, 0, 0.05) 0rem 0rem 0rem 0.0625rem,
    rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(0, 0, 0, 0.05) 0rem 0.25rem 0.375rem -0.0625rem;
}
.shadowXSM {
  box-shadow: 0px 1px 2px rgba(25, 21, 40, 0.04) !important;
}
.tagBoxShadow {
  border: 0.125rem solid #ebe7fc;
  box-shadow: 0rem 0rem 0.625rem rgba(170, 170, 170, 0.25);
  border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
}
.tagBoxShadowRightBottom {
  border: 0.125rem solid #ebe7fc;
  border-top: 0;
  border-left: 0;
  box-shadow: 0.625rem 0.625rem 0.625rem rgba(170, 170, 170, 0.25);
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}

.gradientCardImage {
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.05) 50%,
    rgba(0, 0, 0, 0) 100%
  );
}

.creatorImgCoverGradient {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background: linear-gradient(
      180deg,
      rgba(253, 253, 255, 0.2) 0%,
      rgba(253, 253, 255, 0) 100%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 62.77%, rgba(0, 0, 0, 0.57) 100%);
}

.gradientCover {
  background: linear-gradient(
      180deg,
      rgba(253, 253, 255, 0.2) 0%,
      rgba(253, 253, 255, 0) 44.79%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 46.11%, rgba(0, 0, 0, 0.5) 70.53%);
}

.rotate90 {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}

.boxcenterhv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.boxlefthcenterv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.hand,
.cursorHand {
  cursor: pointer;
}

.input-size {
  width: 100%;
  height: 3rem;
  margin-bottom: 1.5rem;
}

.input-list-search-size {
  height: 2.5rem;
}

.button-size {
  width: 100%;
  height: 3rem;
  margin-bottom: 2.5rem;
}
.button-size-2 {
  height: 3rem;
  margin-bottom: 2.5rem;
}
.form-width {
  min-width: 16rem;
}
.imgIcon28px {
  width: 1.75rem;
  height: 1.75rem;
}
.imgIcon24px {
  width: 1.5rem;
  height: 1.5rem;
}
.imgIcon54px {
  width: 3.375rem;
  height: 3.375rem;
}
.imgIcon50px {
  width: 3.125rem;
  height: 3.125rem;
}
.imgIcon56px {
  width: 3.5rem;
  height: 3.5rem;
}
.imgIcon72px {
  width: 4.5rem;
  height: 4.5rem;
}
.imgIcon68px {
  width: 4.25rem;
  height: 4.25rem;
}
.imgIcon48px {
  width: 3rem;
  height: 3rem;
}
.imgIcon40px {
  width: 2.5rem;
  height: 2.5rem;
}
.imgIcon32px {
  width: 2rem;
  height: 2rem;
}
.imgIcon16px {
  width: 1rem;
  height: 1rem;
}
.imgIcon8px {
  width: 0.5rem;
  height: 0.5rem;
}
.imgIcon12px {
  width: 0.75rem;
  height: 0.75rem;
}

.circle {
  border-radius: 50%;
}
.border-radius-14px {
  border-radius: 0.875rem;
}
.border-radius-16px {
  border-radius: 1rem;
}
.border-radius-16px-bottom {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.border-radius-12px {
  border-radius: 0.75rem;
}
.border-radius-10px {
  border-radius: 0.625rem;
}
.border-radius-10px-top {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}
.border-radius-10px-bottom {
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
}
.border-radius-8px {
  border-radius: 0.5rem !important;
}
.border-radius-8px-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.border-radius-8px-bottom {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.border-radius-8px-right {
  border-bottom-right-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.border-radius-8px-left {
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0.5rem !important;
}
.border-radius-6px {
  border-radius: 0.375rem !important;
}
.border-radius-7px {
  border-radius: 0.4375rem !important;
}
.border-radius-7px-top {
  border-top-left-radius: 0.4375rem !important;
  border-top-right-radius: 0.4375rem !important;
}
.border-radius-7px-bottom {
  border-bottom-left-radius: 0.4375rem;
  border-bottom-right-radius: 0.4375rem;
}
.border-radius-7px-left {
  border-top-left-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
}
.border-radius-4px {
  border-radius: 0.25rem !important;
}
.border-radius-4px-right {
  border-radius: 0rem 0.25rem 0.25rem 0rem;
}
.border-radius-2px {
  border-radius: 0.125rem !important;
}
.border-radius-1px {
  border-radius: 0.0625rem !important;
}

.border-radius-circle {
  border-radius: 50%;
}

.iconHover:hover {
  color: rgba(0, 0, 0, 0.8);
}

.block__title {
  font-size: 0.875rem;
}

.block__text {
  font-size: 0.625rem;
}

.scroll-sticky-top {
  position: sticky;
  top: 0;
}

/* google chrome supports minimum font size 12px */
/*
.chrome12pxfix10px {
  font-size: 12px;
  -webkit-transform: scale(0.83);
} */

/* this is to customize the border color joyride tooltip */
.__floater__open {
  filter: drop-shadow(0px 0px 2px #5b3de3) !important;
}

/* scrollbar style */
* {
  scrollbar-color: #c7bdf6 #ebe7fc;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  background-color: #ebe7fc;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb {
  background-color: #c7bdf6;
  border-radius: 4px;
}
::-webkit-scrollbar-button {
  background-color: #c7bdf6;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: #ebe7fc;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  70% {
    transform: rotate(20deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* file uploader drag style */
.drag-drop-upload-focus {
  box-shadow: 0rem 0rem 0.625rem #5b3de3;
}
.drag-drop-upload-normal {
  box-shadow: none;
}

/* button effects*/

.btn__ {
  text-align: center;
  box-sizing: border-box;
  background: none;
  color: #fff;
  transition: all 0.5s;
}
.btn__:hover {
  cursor: pointer;
}

.btn__effect--3 {
  position: relative;
}
.btn__effect--3:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: attr(data-text);
  transform: translate3d(0, 12px, 0);
  transition: opacity 0.2s 0.25s;
}
.btn__effect--3 span {
  position: relative;
  overflow: hidden;
  display: block;
  width: 95%;
  margin: 0 auto;
  color: transparent;
  transition: color 0.25s ease-in-out;
}
.btn__effect--3 span:after {
  position: absolute;
  top: 50%;
  left: 0;
  content: '';
  width: 100%;
  height: 2px;
  background: #fff;
  transform: translate3d(-100%, 0, 0);
}
.btn__effect--3:hover:before {
  opacity: 0;
  transition-delay: 0s;
}
.btn__effect--3:hover span {
  color: #fff;
  transition-delay: 0.75s;
}
.btn__effect--3:hover span:after {
  transform: translate3d(100%, 0, 0);
  transition: transform 0.75s cubic-bezier(0.7, 0, 0.3, 1) 0.2s;
}

/* writing icon */
.writing-icon {
  border-bottom: 1px solid #fff;
  margin: 0 auto;
  padding: 4px 0;
  width: 40px;
}
.fa {
  font-size: 14px;
  animation: scribble 1s infinite;
}

@keyframes scribble {
  0% {
    transform: rotate(-10deg);
    padding-left: 5px;
    padding-right: 15px;
  }
  50% {
    transform: rotate(-60deg);
    padding-left: 20px;
  }
  100% {
    transform: rotate(-10deg);
    padding-left: 5px;
    padding-right: 15px;
  }
}

.ptr__pull-down--pull-more {
  color: transparent !important;
  text-align: center;
}

.lds-ellipsis div {
  background-color: #5b3de3 !important;
  text-align: center;
}

@keyframes s1-imgs-animation {
  from {
    opacity: 0.8;
    scale: 0.9;
  }
  to {
  }
}

@keyframes s1-title-animation {
  from {
    top: 110%;
    opacity: 0;
    scale: 0.2;
  }
  to {
  }
}

.admined-gradually_show{
  animation: graduallyShow 0.3s ease-in-out;
}
.admined-gradually_hide{
  animation: graduallyHide 0.3s ease-in-out;
}
/* Gradually show */
@keyframes graduallyShow {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Gradually hide */
@keyframes graduallyHide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.transform-opacity_zero_input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
  line-height: 1;
}

.transform-cursor_flashing::after{
  content: "|";
  animation: cursorFlashing 1s infinite;
  font-weight: 400;
}
@keyframes cursorFlashing {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
